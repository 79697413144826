import React from "react";
import errorImage from "url:../assets/images/errorPage.svg";
import errorBackground from "url:../assets/images/errorBackground.svg";

function NoAccess() {
  return (
    <>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{
          width: "100vw",
          height: "100vh",
          position: "relative",
          backgroundColor: "#f0f2f5",
          backgroundImage: `url(${errorBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        <div
          className="d-flex flex-column align-items-center"
          style={{ marginLeft: "5rem" }}
        >
          <h1
            style={{
              fontSize: "5rem",
              fontWeight: "600",
              color: "#01448e",
            }}
          >
            {/* {error.message.includes("404") ? "Error: 404" : " Error: 500"} */}
            No Access
          </h1>
          <h2 style={{ fontSize: "1rem", color: "#01448e" }}>
            {/* {error.message.includes("404")
          ? "Page Not Found!"
          : "Something Went Wrong!"} */}
            Please contact your system administrator
          </h2>
          <br />
          <br />
          {/* <BackButton /> */}
          <br />
          <br />
          {/* <span>Please contact your system administrator </span> */}
        </div>
        <div>
          <img src={errorImage} alt="" width="100%" height="100%" />
        </div>
      </div>
    </>
  );
}

export default NoAccess;
