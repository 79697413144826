import React from "react";
import { BrowserRouter } from "react-router-dom";

import { ThemeProvider, createTheme } from "@mui/material/styles";

import "./sass/index.sass";
import Router from "./Router";
import ErrorBoundary from "./components/errorBoundary/ErrorBoundary";

import { GREEN, NAVBAR_BLUE } from "./utils/constants";
import { AuthProvider } from "./context/authContext";


const theme = createTheme({
  palette: {
    primary: {
      main: NAVBAR_BLUE,
    },
    secondary: {
      main: GREEN,
    },
  },
});

function App() {
  return (
    <>
    <AuthProvider>
      <BrowserRouter>
        <ErrorBoundary>
          <ThemeProvider theme={theme}>
            <Router />
          </ThemeProvider>
        </ErrorBoundary>
      </BrowserRouter>

      </AuthProvider>
    </>
  );
}

export default App;
