/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useExpanded,
} from "react-table";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Input from "@mui/material/Input";

import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";

const Table = forwardRef(
  ({ renderRowSubComponent, data, columns }, currentTable) => {
    const [filterInput, setFilterInput] = useState("");

    const hiddenColumns = ["rowID", "assignedToID"];
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser !== "superuser") {
      hiddenColumns.push("assign");
    }

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
      setGlobalFilter,
      visibleColumns,
    } = useTable(
      {
        columns,
        data,
        initialState: {
          pageSize: 10,
          pageIndex: 0,
          hiddenColumns,
        },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      useExpanded,
      usePagination
    );
    const handleFilterChange = useAsyncDebounce((searchFilterInput) => {
      setGlobalFilter(searchFilterInput || undefined);
    }, 200);

    const handleCurrentPageChange = (event) => {
      const currentPageNumber = event.target.value
        ? Number(event.target.value) - 1
        : 0;
      gotoPage(currentPageNumber);
    };

    return (
      <>
        <div className="d-flex justify-content-end mb-1">
          <span className="mx-1">
            <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }}>
              <InputLabel
                id="perPageFilter"
                style={{ fontSize: "0.8rem", color: "#9e9e9e" }}
              >
                Per Page:
              </InputLabel>
              <Select
                labelId="perPageFilter"
                value={pageSize}
                onChange={(event) => {
                  setPageSize(Number(event.target.value));
                }}
                label="Per Page"
                style={{ fontSize: "0.8rem" }}
              >
                {/* Number of rows per page */}
                {[10, 25, 50, 100].map((rowsPerPage) => (
                  <MenuItem
                    key={`rowsPerPage-${rowsPerPage}`}
                    value={rowsPerPage}
                  >
                    {rowsPerPage}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </span>
          <FormControl
            variant="standard"
            sx={{ m: 1, mt: 2.5, width: "27ch", marginRight: "-50px" }}
          >
            <Input
              value={filterInput || ""}
              onChange={(event) => {
                setFilterInput(event.target.value);
                handleFilterChange(event.target.value);
              }}
              placeholder="Search"
            />
          </FormControl>
        </div>
        <div style={{ marginLeft: "55px", marginRight: "-55px" }}>
          <table {...getTableProps()} ref={currentTable}>
            <thead>
              {headerGroups.map((headerGroup) => {
                const { key: columnHeaderGroupKey, ...restHeaderGroupProps } =
                  headerGroup.getHeaderGroupProps();
                return (
                  <tr
                    key={`table-header-${columnHeaderGroupKey}`}
                    {...restHeaderGroupProps}
                  >
                    {headerGroup.headers.map((column) => {
                      const { key: columnHeaderKey } = column.getHeaderProps();
                      return (
                        <th
                          key={`table-column-${columnHeaderKey}`}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <div className="d-flex flex-row align-items-center ">
                            <span style={{ color: "#505050" }}>
                              {" "}
                              {column.render("Header")}
                            </span>

                            {/* Add a sort direction indicator */}
                            {column.id !== "expander" ? (
                              <>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <ArrowDropDown />
                                  ) : (
                                    <ArrowDropUp />
                                  )
                                ) : (
                                  <span
                                    className="d-flex flex-column align-items-center mb-1"
                                    style={{ position: "relative" }}
                                  >
                                    <ArrowDropUp />
                                    <span
                                      style={{
                                        position: "absolute",
                                        top: "5px",
                                      }}
                                    >
                                      <ArrowDropDown />
                                    </span>
                                  </span>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                const { key: rowKey, rowProps } = row.getRowProps();
                return (
                  <React.Fragment key={`table-row-${rowKey}`}>
                    <tr {...rowProps}>
                      {row.cells.map((cell) => {
                        const { key: cellKey } = cell.getCellProps();
                        return (
                          <td
                            key={`table-cel-${cellKey}`}
                            className="cell"
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                    {row.isExpanded &&
                      renderRowSubComponent({ row, rowProps, visibleColumns })}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <div> </div>
        <div
          className="d-flex align-items-center justify-content-end mt-3 "
          style={{ fontSize: "0.8rem", marginRight: "-88px" }}
        >
          <span
            className={
              canPreviousPage ? "btn-pagination" : "btn-pagination-disabled"
            }
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </span>
          <span className="mx-1 ">
            Page{" "}
            <strong>
              <input
                className="pagination-page-count"
                type="number"
                value={pageIndex + 1}
                onChange={handleCurrentPageChange}
              />{" "}
              of {pageCount}
            </strong>{" "}
          </span>
          <span
            className={
              canNextPage ? "btn-pagination" : "btn-pagination-disabled"
            }
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </span>
        </div>
      </>
    );
  }
);

export default Table;
