import React, { useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import logo from "url:../../assets/images/sherlock_logo.png";

import { HOME_TAB, WHITE, DJANGO_APP_HOME, HOME } from "../../utils/constants";
import callLogout from "../../utils/callLogout";
import DateFilter from "../dateFilter/DateFilter";
import Side from "../sidebar/Side";
import SetClient from "../setClient/SetClient";
import { CLIENT_OPTION_VISIBILITY } from "../../utils/constants";
export default function Navbar({
  selectedMonth,
  setSelectedMonth,
  isClicked,
  setIsClicked,
  client,
  setClient,
  currentYear,
  setCurrentYear,
}) {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const [open, setOpen] = useState(false);
  const [locaccount, setLocaccount] = useState("160px");

  const history = useHistory();

  const { pathname } = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState({ isOpen: false });

  let pathparams = pathname.split("/");
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let month =
    pathparams.length > 2
      ? pathparams[2] === ""
        ? months[currentMonth - 1]
        : pathparams[2] === "01"
        ? "Jan"
        : pathparams[2] === "02"
        ? "Feb"
        : pathparams[2] === "03"
        ? "Mar"
        : pathparams[2] === "04"
        ? "Apr"
        : pathparams[2] === "05"
        ? "May"
        : pathparams[2] === "06"
        ? "June"
        : pathparams[2] === "07"
        ? "July"
        : pathparams[2] === "08"
        ? "Aug"
        : pathparams[2] === "09"
        ? "Sep"
        : pathparams[2] === "10"
        ? "Oct"
        : pathparams[2] === "11"
        ? "Nov"
        : "Dec"
      : months[currentMonth - 1];

  let tabName;
  if (pathparams[1] === "uploadexcel") {
    document.title = "Sherlock AI | Upload Excel";
    tabName = "Upload Claims Excel";
  } else if (pathparams[1] === "downloadexcel") {
    tabName = "Download Claims Excel";
    document.title = "Sherlock AI | Download Excel";
  } else if (pathparams[1] === "exceltemplate") {
    tabName = "Claims Excel Template";
    document.title = "Sherlock AI | Excel Template";
  } else if (pathparams[1] === "throughput_report") {
    tabName = `Claims Throughput Report (${
      months[selectedMonth - 1]
        ? months[selectedMonth - 1]
        : months[currentMonth - 1]
    }, ${pathparams[3] ? pathparams[3] : currentYear})`;
    document.title = "Sherlock AI | Throughput Report";
  } else {
    document.title = "Sherlock AI";
    tabName = "";
  }

  const logoutMenuRef = useRef(null);

  const [isLogoutVisible, setIsLogoutVisible] = useState(false);
  const toggleLogoutMenu = () => {
    setIsLogoutVisible((prevValue) => {
      return !prevValue;
    });
  };

  const handleLogout = () => {
    callLogout("GET", "logout/", "",client)
      .then(() => {
        document.cookie.split(";").forEach((cookie) => {
          document.cookie = cookie
            .replace(/^ +/, "")
            .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
        });
      })
      .then(() => {
        window.location.replace(`${HOME}/login`);
        
      });
  };

  return (
    <>
      <AppBar
        position="sticky"
        className="navbar__wrapper d-flex justify-content-center"
        style={{
          backgroundColor: WHITE,
          color: "#000000",
          boxShadow: "none",
          backgroundColor: "white",
          borderStyle: "none",
        }}
        onClick={(event) => {
          if (
            logoutMenuRef.current &&
            !logoutMenuRef.current.contains(event.target)
          ) {
            setIsLogoutVisible(false);
          }
        }}
      >
        <div style={{ marginLeft: "30px" }}>
          <Side
            isClicked={isClicked}
            setIsClicked={setIsClicked}
            client={client}
            setClient={setClient}
          />
        </div>
        <div className="d-flex justify-content-between">
          <div
            className="d-flex"
            style={{
              fontSize: "20px",
              color: "black",
              marginLeft: "50px",
              marginRight: "30px",
              marginTop: "10px",
            }}
          >
            <div className="mt-2">
              <img src={logo} />
              <span className="ms-2"> Sherlock AI</span>
            </div>
          </div>
          <div
            // style={{
            //   position: "fixed",
            //   paddingLeft:
            //     pathparams[1] === "throughput_report" ? "450px" : "550px",
            // }}
            className="mt-1"
          >
            <DateFilter
              open={open}
              setOpen={setOpen}
              selectedMonth={selectedMonth}
              setSelectedMonth={setSelectedMonth}
              currentYear={currentYear}
              setCurrentYear={setCurrentYear}
            />
            <div
              className="d-flex link"
              style={{
                textAlign: "center",
                marginTop: "15px",
                // marginRight: "6rem",
                // marginLeft: "-8rem"
                // position: "fixed",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  color: "#305697",
                  // paddingLeft: "80px",
                }}
                onClick={
                  pathparams[1] === "throughput_report"
                    ? () => setOpen(!open)
                    : ""
                }
              >
                {tabName}
              </span>
            </div>
          </div>

          <div className="mt-2 d-flex">
            <div style={{ marginRight: "16px", display: CLIENT_OPTION_VISIBILITY === "No" ? "none" : "" }}>
              <SetClient client={client} setClient={setClient} />
            </div>
            <a
              ref={logoutMenuRef}
              onClick={toggleLogoutMenu}
              className="logout-link "
            >
              <AccountCircleIcon
                style={{
                  fontSize: "36px",
                  color: "#305697",
                  verticalAlign: "middle",

                  marginRight: "20px",
                  marginTop: "10px",
                }}
              />
            </a>

            {isLogoutVisible && (
              <ul
                id="account-dropdown"
                className="dropdown-content"
                tabIndex="0"
                style={{
                  display: "block",
                  width: "100px",
                  right: "10px",
                  top: "0px",
                  height: "60px",
                  transformOrigin: "100% 0px",
                  opacity: "1",
                  transform: "scaleX(1) scaleY(1)",
                  backgroundColor: "#fff",
                  margin: "0",
                  minWidth: "100px",
                  overflowY: "none",
                  paddingLeft: "0",
                  listStyleType: "none",
                  boxShadow:
                    "0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%)",
                  position: "absolute",
                  zIndex: "9999",
                }}
              >
                <li
                  tabIndex="0"
                  style={{
                    boxSizing: "border-box",
                    fontSize: "20px",
                    marginRight: "10px",
                    clear: "both",
                    color: "rgba(0,0,0,0.87)",
                    cursor: "pointer",
                    minHeight: "50px",
                    lineHeight: "1.5rem",
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  <a
                    id="logout-button"
                    style={{
                      fontSize: "16px",
                      color: "#26a69a",
                      display: "block",
                      lineHeight: "22px",
                      padding: "20px 16px",
                    }}
                    onClick={handleLogout}
                  >
                    Logout
                  </a>
                </li>
              </ul>
            )}
          </div>
        </div>
      </AppBar>
    </>
  );
}
