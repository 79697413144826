import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import "../sass/login.sass";

import PerceptivitiLogo from "../assets/images/perceptiviti_logo.svg";
// import logo from "../assets/logo.webp";

import callAPI from "../utils/callAPI";
import { useAuth } from "../context/authContext";

export default function Login({ client }) {
  const history = useHistory();
  const loggedInUser = localStorage.getItem("user");
  const location = useLocation();
  const auth = useAuth();
  const forgotPassword = useRef(null);
  const { from } = location.state || { from: { pathname: "/" } };
  const error = auth.status === 400;
  // const error = false;
  useEffect(() => {
    if (loggedInUser === "undefined") {
      localStorage.clear();
    }
    if (loggedInUser !== null) {
      history.push(from.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, loggedInUser]);

  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
    showPassword: false,
  });

  const toggleForgotPasswordContainer = () => {
    if (forgotPassword.current.style.display === "block") {
      forgotPassword.current.style.display = "none";
    } else {
      forgotPassword.current.style.display = "block";
    }
  };

  const handleChange = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    setCredentials((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  const handleClickShowPassword = () => {
    setCredentials({ ...credentials, showPassword: !credentials.showPassword });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const body = JSON.stringify({
      username: credentials.username,
      password: credentials.password,
    });
    callAPI("POST", "login/", body, client).then((res) => {
      const userName = res.user;
      if (res.status === 200) {
        auth.signin(200, userName);
        history.push(from.pathname);
      } else {
        auth.signin(400, userName);
      }
    });
    // auth.signout(() => history.push("/"));
  };
  return (
    <>
      {loggedInUser !== null ? (
        ""
      ) : (
        <>
          <div className="login-page">
            <div className="logo-container">
              <div className="logo">
                <img src={PerceptivitiLogo} alt="" width="170" height="170" />
              </div>
            </div>

            <div className="main-login-container">
              <div className="login-image-container" />
              <div className="login-form-container">
                <div className="d-flex justify-content-center">
                  {/* <img src={logo} alt="" width="80" height="80" /> */}
                </div>
                <div className="product-name-container d-flex justify-content-center align-items-center">
                  <h3>Welcome to Sherlock.AI</h3>
                </div>

                {/* {% if form.errors %} */}
                <div style={{ position: "relative" }}>
                  {auth.status === 400 ? (
                    // {false? (
                    <p className="login-error">
                      Your username and password did not match. Please try
                      again.
                    </p>
                  ) : (
                    ""
                  )}
                  {/* {% endif %} {% if next %} {% if user.is_authenticated %} 
              <p>
                Your account doesn't have access to this page. To proceed,
                please login with an account that has access.
              </p>
              {/* {% else %} */}
                  {/* {console.log(from.pathname)}
                  {from.pathname === "/" || null ? (
                    
                    <p className="login-error mt-1">
                      Please login to see this page.
                    </p>
                  ) : (
                    ""
                  )} */}
                  {/* {% endif %} */}
                </div>
                {/* {% endif %} */}
                <form
                  method="post"
                  encType="multipart/form-data"
                  onSubmit={handleSubmit}
                >
                  {/* <p className="input-label">Username</p>
            <input
              className={auth.status === 400 ? "triggered" : ""}
              type="text"
              name="username"
              placeholder="Enter your username"
              value={credentials.username}
              onChange={handleChange}
            />
            <p className="input-label">Password</p>
            <input
              className={auth.status === 400 ? "triggered" : ""}
              type="password"
              placeholder="Enter your password"
              name="password"
              value={credentials.password}
              onChange={handleChange}
            /> */}

                  <FormControl
                    className="mt-3"
                    style={{ width: "100%" }}
                    fullWidth
                    variant="outlined"
                  >
                    <InputLabel htmlFor="username">Username</InputLabel>
                    <OutlinedInput
                      error={error}
                      id="username"
                      type="text"
                      value={credentials.username}
                      onChange={handleChange}
                      name="username"
                      labelWidth={75}
                    />
                  </FormControl>
                  <FormControl
                    className="mt-3"
                    style={{ width: "100%" }}
                    variant="outlined"
                  >
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <OutlinedInput
                      className="no-bottom-border"
                      error={error}
                      id="password"
                      type={credentials.showPassword ? "text" : "password"}
                      value={credentials.password}
                      onChange={handleChange}
                      name="password"
                      labelWidth={70}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {credentials.showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>

                  <div className="warning-container">
                    <span
                      tabIndex="0"
                      role="button"
                      className="warning-trigger"
                      onClick={toggleForgotPasswordContainer}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          toggleForgotPasswordContainer(event);
                        }
                      }}
                    >
                      Forgot Password?
                    </span>

                    <p
                      ref={forgotPassword}
                      style={{ display: "none" }}
                      className="warning-message"
                    >
                      Please contact your system administrator for resetting the
                      password.
                    </p>
                  </div>
                  <button
                    type="submit"
                    value="login"
                    className="btn signin-button"
                  >
                    Sign In
                  </button>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
