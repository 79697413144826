import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useDropzone } from "react-dropzone";
import imageUnavailable from "url:../../assets/images/imageUnavailable.svg";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import callAPI from "../../utils/callAPI";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useHistory } from "react-router-dom";
import useAsyncError from "../../customHooks/useAsyncError";

const Input = styled("input")({
  display: "none",
});

const baseStyle = {
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  color: "#bdbdbd",
  transition: "border .3s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function documentUpload({
  files,
  setFiles,
  switchValue,
  setOpenDialog,
  setIsprocessing,
  setIsSuccess,
  setDocumentProcessResponse,
  client,
}) {
  const history = useHistory();
  const throwError = useAsyncError();
  const [isBgColor, setIsBgColor] = useState("#009933");
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );

      const formData = new FormData();
      formData.append("data", acceptedFiles[0]);
      setIsprocessing(true);
      if (switchValue) {
        formData.append("documentType", "historic");
      } else {
        formData.append("documentType", "live");
      }
      
      callAPI("GET", "logged_in/", "", client).then((res) => {
        if (!res.is_authenticated) {
          localStorage.removeItem("user");
          history.push("/login");
        } else {
          callAPI("POST", `upload/`, formData, client)
            .then((res) => {
              setDocumentProcessResponse({ ...res });
              if (res.processed) {
                setIsSuccess(true);
              } else {
                setIsSuccess(false);
              }
              setIsprocessing(false);
              setOpenDialog(true);
            })
            .catch((error) => {
              throwError(new Error(error));
            });
        }
      });
    },
    [switchValue,client]
  );

  const handleRemove = (file) => {
    const newFiles = [...files];
    newFiles.splice(file, 1);
    setFiles(newFiles);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: ".xlsx, .xlsb, .xls, .csv",
    multiple: false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  // clean up
  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <>
      {!files.length >= 1 ? (
        <>
          <div className="upload__container container mt-3 mb-4 p-0">
            <div {...getRootProps({ className: "dropzone", style })}>
              <div className="row pt-5 d-flex justify-content-center align-items-center">
                <div className="col-12 upload__img  d-flex justify-content-center align-items-center">
                  <img src={imageUnavailable} height="100" width="100" />
                </div>
              </div>
              <div className="row mt-4 pb-5">
                <div className="col-12 d-flex justify-content-center align-items-center">
                  <label htmlFor="contained-button-file">
                    <Input {...getInputProps()} />
                    <Button
                      className="text-light"
                      variant="contained"
                      component="span"
                      sx={{
                        backgroundColor: isBgColor,
                        ":hover": {
                          bgcolor: "#4caf50", // theme.palette.primary.main
                          color: "white",
                        },
                      }}
                    >
                      Select Document
                    </Button>
                    <p className="mt-2">or drop document here</p>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <aside
          className="thumbnail__container container row my-5 d-flex flex-row flex-wrap"
          style={style}
        >
          {files.map((file) => (
            <div
              key={file.name}
              className="col-3 p-2 d-flex flex-row align-items-center "
            >
              <div className="w-100 h-100 border m-2 p-2 d-flex justify-content-between">
                <div className="text-start text-break">
                  {/* <img src={file.preview} alt={file.name} /> */}
                  <span className="text-start text-break">{file.name}</span>
                </div>
                <HighlightOffIcon
                  className=""
                  style={{ color: "#ff1744" }}
                  type="button"
                  onClick={() => handleRemove(file)}
                />
              </div>
            </div>
          ))}
        </aside>
      )}
    </>
  );
}
