import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import { API_HOST, HOME } from "../../utils/constants";

const useStyles = makeStyles({
  newPosOfDialog: {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-55%, -60%)",
    borderRadius: "20px",
  },
});

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const currentDate = new Date();
function DateFilter({
  open,
  setOpen,
  selectedMonth,
  setSelectedMonth,
  currentYear,
  setCurrentYear,
}) {
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());

  const history = useHistory();
  const [namepath, setNamePath] = useState("uploadexcel");
  const classes = useStyles();
  const { pathname } = useLocation();
  let pathparams = pathname.split("/");
  const handleClick = (month) => {
    month = months.indexOf(month) + 1;
    setSelectedMonth(month);
    setOpen(false);
    // window.location.replace(`${HOME}/throughput_report/${month.toString().length < 2 ? "0"+month : month}/${currentYear}`)
  };
  return (
    <div>
      <Dialog
        classes={{
          paper: classes.newPosOfDialog,
        }}
        open={open}
        onClose={() => setOpen(false)}
        fullWidth={true}
        maxWidth="md"
        style={{ padding: "24px" }}
      >
        <DialogTitle
          style={{ textAlign: "center", fontSize: "0.9rem", color: "#505050" }}
        >
          REPORT MONTH
        </DialogTitle>
        <DialogContent
          style={{ padding: "0 24px", color: "#305697", fontSize: "16px" }}
        >
          <div className="d-flex" style={{ cursor: "pointer" }}>
            <div
              className="pb-2"
              style={{
                borderBottom: "2px solid #305697",
                width: "100%",
                height: "100%",
                textAlign: "center",
                backgroundColor:
                  currentYear === 2022 ? "rgb(220 231 249 / 44%)" : "",
              }}
              onClick={() => {
                setCurrentYear(2022);
                setCurrentMonth(11);
              }}
            >
              2022
            </div>
            <div
              className="pb-2"
              style={{
                borderBottom: "2px solid #305697",
                width: "100%",
                height: "100%",
                textAlign: "center",
                backgroundColor:
                  currentYear === 2023 ? "rgb(220 231 249 / 44%)" : "",
              }}
              onClick={() => {
                setCurrentYear(2023);
                setCurrentMonth(0);
              }}
            >
              2023
            </div>
          </div>
          <div
            className="d-flex flex-wrap "
            style={{
              lineHeight: "48px",
              fontSize: "16px",
              textAlign: "center",
              padding: "1rem",
            }}
          >
            {months.map((month, index) => {
              if (index <= currentMonth) {
                return (
                  <div className="mx-2" style={{ width: "31%" }}>
                    <button
                      style={{
                        backgroundColor: "Transparent",
                        backgroundRepeat: "no-repeat",
                        border: "none",
                        color: "#305697",
                      }}
                      value={month}
                      key={month}
                      onClick={(e) => handleClick(e.target.value)}
                    >
                      {month}
                    </button>
                  </div>
                );
              }
              return "";
            })}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DateFilter;
