import { API_HOST } from "./constants";
let csrfToken = null;



export default async function callAPI(method, route, body, client) {
  async function getCsrfToken() {
    const response = await fetch(`${API_HOST}/${client}/api/csrf/`, {
      credentials: "include",
    });
    const data = await response.json();
    csrfToken = data.csrfToken;
    return csrfToken;
  }
  let response;
  if (method === "POST") {
    response = await fetch(`${API_HOST}/${client}/api/${route}`, {
      method,
      headers: { "X-CSRFToken": await getCsrfToken() },
      body,
      credentials: "include",
    });
  } else if (method === "GET") {
    response = await fetch(`${API_HOST}/${client}/api/${route}`, {
      method,
      headers: {},
      credentials: "include",
    });
  }

  const data = await response.json();
  return data;
}
